<script setup lang="ts">
definePageMeta({
  nested: false,
  showCart: true,
  shareable: true,
  keepalive: true,
  scrollToTop: false,
  context: 'products.list',
})

if (process.client) {
  const router = useRouter()
  const localePath = useLocalePath()
  const globalStore = useGlobalStore()

  if (globalStore.storeIsAvailable) {
    router.push(localePath({ name: 'products' }))
  }
}

const { t } = useI18n()
const { send } = useWhatsappMessage()

const getInTouch = () => {
  send(t('pages.notAvailable.whatsappMessage'))
}
</script>

<template>
  <div>
    <RAppStoreBanner />

    <div class="m-auto flex max-w-[1280px] items-center justify-center p-4 py-8 md:py-16">
      <div
        class="flex max-w-[624px] flex-col items-center gap-6 sm:flex-row sm:items-start sm:gap-16"
      >
        <img
          class="h-[96px] w-[96px] sm:h-[200px] sm:w-[200px]"
          alt=""
          src="/images/disconnected.png"
        />
        <div
          class="flex flex-col items-center justify-center gap-6 text-center text-gray-400 sm:items-start sm:text-left"
        >
          <div class="text-lg font-semibold sm:text-2xl">{{ t('pages.notAvailable.title') }}</div>
          <div class="text-sm font-medium sm:text-base">
            {{ t('pages.notAvailable.subtitle') }}
          </div>
          <div>
            <RBaseButton
              color="primary"
              class="px-4 py-3 text-[12px] uppercase sm:text-sm"
              @click="getInTouch"
            >
              {{ t('pages.notAvailable.ctaButton') }}
            </RBaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
